<template>
  <section v-if="information">
    <div class="mt-2">{{ $t("INFORMATION") }}</div>

    <div class="clearfix">
      <b-table-simple>
        <b-tbody>
          <b-tr>
            <b-td class="w-150">{{ $t("NAME") }}</b-td>
            <b-td>{{ name }}</b-td>
          </b-tr>
          <b-tr>
            <b-td class="w-150">{{ $t("DESCRIPTION") }}</b-td>
            <b-td>{{ description }}</b-td>
          </b-tr>
          <b-tr>
            <b-td class="w-150">{{ $t("IDENTIFIER") }}</b-td>
            <b-td>{{ identifier }}</b-td>
          </b-tr>
          <b-tr>
            <b-td class="w-150">{{ $t("ORGANISATION.TITLE") }}</b-td>
            <b-td>{{ organisationName }}</b-td>
          </b-tr>
          <b-tr>
            <b-td class="w-150">{{ $t("ORGANISATION.TITLE") }} Id</b-td>
            <b-td>{{ organisationId }}</b-td>
          </b-tr>
          <b-tr>
            <b-td class="w-150">{{ $t("ID") }}</b-td>
            <b-td>{{ id }}</b-td>
          </b-tr>
        </b-tbody>

      </b-table-simple>
    </div>

    <div class="mt-2 mb-2" v-if="loaded_address">
      <b-button @click="saveAddress" size="sm" v-if="!hideSave && show_save" variant="primary"><i class="fa-sharp fa-regular fa-bookmark"></i> {{
        $t("SDK.SAVE_RECIPIENT")
      }}</b-button>

    </div>
  </section>
</template>
<script>
export default {
  props: {
    showSend: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    information: {
      type: Object,
      default: null
    },
    hideSave: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    functionbox_uuid: {
      default: "",
      type: String,
    }
  },
  data() {
    return {
      info: this.information,
      loading: false,
      saved_address: [],
      loaded_address: false
    };
  },
  methods: {
    async init() {
      await this.getSavedAddresses();
    },
    async getSavedAddresses() {
      try {
        let result = await this.$http.get(this.user.hostname + "/sdk/address/" + this.functionbox_uuid);
        for(let ix = 0;ix < result.data.length; ix++)
        {
          if(result.data[ix].sdk_identifier == this.identifier)
          {
            this.currentId = result.data[ix].id;
          }
        }
        this.saved_address = result.data;
      } catch {
        console.log("Could not getSavedAddresses");
      } 
      self.loaded_address = true;
    },
    async saveAddress() {
      let AddSdkSefosAddressDTO = {
        functionbox_uuid: this.functionbox_uuid,
        sdk_organisation: this.item.relationships.parent.data.identifier,
        sdk_identifier: this.item.attributes.identifier,
      };
      try {
        let result = await this.$http.post(this.user.hostname + "/sdk/address/add", AddSdkSefosAddressDTO);
        this.currentId = result.data.id;
        this.getSavedAddresses();
      } catch {
        console.log("Could not saveAddress");
      } 
      this.loading = false;
    },
    async removeAddress() {
      let self = this;
      self.loading = true;
      let id_list = [];
      id_list.push(this.currentId);
      this.$bvModal
        .msgBoxConfirm(this.$t("ARE_YOU_SURE"), {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async function (value) {
          if (value) {
            let RemoveSdkSefosAddressDTO = {
              id_list: id_list,
              functionbox_uuid: self.functionbox_uuid,
            };
            await self.$http
              .post(
                self.user.hostname + "/sdk/address/remove",
                RemoveSdkSefosAddressDTO
              )
              .then(function () {
                self.getSavedAddresses();
                self.loading = false;
              })
              .catch(function () {
                self.loading = false;
              });
          }
        })
        .catch(function () {});
    }
  },
  computed: {
    name(){
      return this.showSend ? this.info.addressBookFromName : this.info.addressBookToName;
    },
    description(){
      return this.showSend ? this.info.addressBookFromDescription :  this.info.addressBookToDescription;
    },
    identifier(){
      return this.showSend ? this.info.FromParticipantFunctionId : this.info.ToParticipantFunctionId;
    },
    organisationName(){
      return this.showSend ? this.info.addressBookFromOrganisationName : this.info.addressBookToOrganisationName;
    },
    organisationId(){
      return this.showSend ? this.info.FromParticipantOrganisationId : this.info.ToParticipantOrganisationId;
    },
    id(){
      return this.showSend ?  this.info.addressBookFromId : this.info.addressBookToId;
    },
    show_save() {
      let identifier = this.identifier;
      return (
        this.saved_address.filter(function (item) {
          return item.sdk_identifier == identifier;
        }).length == 0
      );
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style></style>
