var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.information
    ? _c("section", [
        _c("div", { staticClass: "mt-2" }, [
          _vm._v(_vm._s(_vm.$t("INFORMATION"))),
        ]),
        _c(
          "div",
          { staticClass: "clearfix" },
          [
            _c(
              "b-table-simple",
              [
                _c(
                  "b-tbody",
                  [
                    _c(
                      "b-tr",
                      [
                        _c("b-td", { staticClass: "w-150" }, [
                          _vm._v(_vm._s(_vm.$t("NAME"))),
                        ]),
                        _c("b-td", [_vm._v(_vm._s(_vm.name))]),
                      ],
                      1
                    ),
                    _c(
                      "b-tr",
                      [
                        _c("b-td", { staticClass: "w-150" }, [
                          _vm._v(_vm._s(_vm.$t("DESCRIPTION"))),
                        ]),
                        _c("b-td", [_vm._v(_vm._s(_vm.description))]),
                      ],
                      1
                    ),
                    _c(
                      "b-tr",
                      [
                        _c("b-td", { staticClass: "w-150" }, [
                          _vm._v(_vm._s(_vm.$t("IDENTIFIER"))),
                        ]),
                        _c("b-td", [_vm._v(_vm._s(_vm.identifier))]),
                      ],
                      1
                    ),
                    _c(
                      "b-tr",
                      [
                        _c("b-td", { staticClass: "w-150" }, [
                          _vm._v(_vm._s(_vm.$t("ORGANISATION.TITLE"))),
                        ]),
                        _c("b-td", [_vm._v(_vm._s(_vm.organisationName))]),
                      ],
                      1
                    ),
                    _c(
                      "b-tr",
                      [
                        _c("b-td", { staticClass: "w-150" }, [
                          _vm._v(_vm._s(_vm.$t("ORGANISATION.TITLE")) + " Id"),
                        ]),
                        _c("b-td", [_vm._v(_vm._s(_vm.organisationId))]),
                      ],
                      1
                    ),
                    _c(
                      "b-tr",
                      [
                        _c("b-td", { staticClass: "w-150" }, [
                          _vm._v(_vm._s(_vm.$t("ID"))),
                        ]),
                        _c("b-td", [_vm._v(_vm._s(_vm.id))]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm.loaded_address
          ? _c(
              "div",
              { staticClass: "mt-2 mb-2" },
              [
                !_vm.hideSave && _vm.show_save
                  ? _c(
                      "b-button",
                      {
                        attrs: { size: "sm", variant: "primary" },
                        on: { click: _vm.saveAddress },
                      },
                      [
                        _c("i", {
                          staticClass: "fa-sharp fa-regular fa-bookmark",
                        }),
                        _vm._v(" " + _vm._s(_vm.$t("SDK.SAVE_RECIPIENT"))),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }